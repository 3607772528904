import { Flex, FlexItem } from '@patternfly/react-core';
import Setup from './Setup';
import Avatar from './Avatar';
import Notifications from './Notifications';
import React, { useState } from 'react';
import HeaderMenuItems from './HeaderMenuItems';
import { HeaderMenuItem } from '../../helpers/headerHelper';
import { useIsGranted } from '../../components/user/ApplicationProvider';
import { Permission } from '../../enums/permission.enum';
import BuilderZoneTag from './BuilderZoneTag';
import { useMount } from 'react-use';
import { ZoneSetting, ZoneSettingEnum } from '../../api/zone/ZoneSetting';

type Props = {
	menuItems: HeaderMenuItem[];
};

const HeaderContent = (props: Props) => {
	const { menuItems } = props;
	const isGranted = useIsGranted();
	const hasViewNotificationsPerm = isGranted(Permission.ViewZoneNotification);
	const hasViewSetupPerm = isGranted(Permission.CanViewSetupPage);
	const [isBuilderZone, setIsBuilderZone] = useState<boolean>(false);

	useMount(() => {
		void ZoneSetting.Get(ZoneSettingEnum.isbuilderzone).then((setting) => {
			const isBuilderZone = setting.value.toLowerCase() == 'true';
			setIsBuilderZone(isBuilderZone);
		});
	});

	return (
		<Flex
			justifyContent={{ default: 'justifyContentSpaceBetween' }}
			alignItems={{ default: 'alignItemsCenter' }}
		>
			<HeaderMenuItems headerMenuItems={menuItems} />
			<FlexItem>
				<Flex>
					{isBuilderZone && (
						<FlexItem>
							<BuilderZoneTag />
						</FlexItem>
					)}
					{hasViewSetupPerm && (
						<FlexItem style={{ margin: 0 }}>
							<Setup />
						</FlexItem>
					)}
					{hasViewNotificationsPerm && (
						<FlexItem style={{ margin: 0 }}>
							<Notifications />
						</FlexItem>
					)}
					<FlexItem>
						<Avatar />
					</FlexItem>
				</Flex>
			</FlexItem>
		</Flex>
	);
};

export default HeaderContent;
