import React, { ReactElement, useState } from 'react';
import {
	Brand,
	Masthead,
	MastheadMain,
	MastheadContent,
	MastheadBrand,
	Tooltip,
	Text,
} from '@patternfly/react-core';
import './Header.scss';
import HeaderContent from './HeaderContent';
import { HeaderMenuItem } from '../../helpers/headerHelper';
import { useNavigate } from 'react-router-dom';
import { useMount } from 'react-use';
import { ZoneSetting, ZoneSettingEnum } from '../../api/zone/ZoneSetting';

type Props = {
	headerText?: string;
	logoImg: string;
	menuItems: HeaderMenuItem[];
};

export default function Header(props: Props): ReactElement {
	const { logoImg, menuItems } = props;
	const navigate = useNavigate();
	const [isFrameEnabled, setIsIframeEnabled] = useState<boolean>(false);

	useMount(() => {
		void ZoneSetting.Get(ZoneSettingEnum.iframeIntegration).then((setting) => {
			if (setting.enabled) {
				setIsIframeEnabled(true);
			}
		});
	});
	return (
		<Masthead style={{ paddingLeft: 0 }}>
			<MastheadMain>
				{!isFrameEnabled && (
					<MastheadBrand className="pf-m-align-items-center pf-m-justify-content-space-between">
						<Tooltip
							content="Home"
							flipBehavior={['bottom']}
						>
							<Text
								style={{ display: 'inline-flex', fontSize: '24px', color: 'white' }}
								onClick={() => navigate('/')}
							>
								<Brand
									alt={'ZeroedIn Technologies'}
									src={logoImg}
									className="zin-header-logo"
								/>
							</Text>
						</Tooltip>
					</MastheadBrand>
				)}
				<MastheadContent>
					<HeaderContent menuItems={menuItems} />
				</MastheadContent>
			</MastheadMain>
		</Masthead>
	);
}
