import React, { useEffect, useRef, useState } from 'react';
import { DashboardWidget } from '../../../types/dashboards/dashboard';
import { TNewDateRange } from '../../../api/types/TNewDateRange';
import { DashboardFilter } from '../../../api/dashbboards/DashboardFilter';
import { useToast } from '@zeroedin-tech/zi-common-ui/lib';
import { useApplication } from '../../user/ApplicationProvider';
import { DateRange } from '../../../api/date-period-selector/DateRange';
import { TDataframe, TDateRange } from '../../../api/types';
import { useMount } from 'react-use';
import { DashboardWidget as DashboardWidgetService } from '../../../api/dashbboards/DashboardWidgets';
import Loader from '../../util/Loader';
import {
	AlertVariant,
	Button,
	Dropdown,
	DropdownItem,
	DropdownToggle,
	InputGroup,
	Modal,
	ModalVariant,
	Text,
	Tooltip,
} from '@patternfly/react-core';
import { Editor } from 'react-draft-wysiwyg';
import { ContentState, EditorState, Modifier, SelectionState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { IIndexable } from '../../../types/general';
import './TextView.scss';
import ConditionalFormattingModal from '../../modals/databuilder/ConditionalFormattingModal';
import { DraggableMenuItemData } from '../../../types/databuilder/databuilder';
import {
	buildDataframeRequest,
	populateDataForFact,
	populateDroppedFactsByKeyMeasure,
} from '../../../hooks/DataBuilderHooks';
import { generateGUID } from '../../../helpers/guid.helper';
import { OptionsBuilderItemTypes } from '../../../types/dataframes/options-builder-item-types';
import _ from 'lodash';
import ZiCreateSelectionModal, {
	ViewTypesEnum,
	ZiCreateSelectionOptionsModalProps,
} from '../../modals/shared/creation-selection/ZiCreateSelectionModal';
import { faChartLineUp, faDatabase } from '@fortawesome/pro-regular-svg-icons';
import { TKeyMeasureFact } from '../../../api/analytics/KeyMeasureFact';
import { Dataframe, TNewDataframe } from '../../../api/dataframes/Dataframes';
import TimesIcon from '@patternfly/react-icons/dist/esm/icons/times-icon';
import CopyIcon from '@patternfly/react-icons/dist/esm/icons/copy-icon';
import CogIcon from '@patternfly/react-icons/dist/esm/icons/cog-icon';
import InfoCircleIcon from '@patternfly/react-icons/dist/esm/icons/info-icon';
import { ConditionalRule } from '../../../types/conditional-rules/conditional-rules';

type Props = {
	widget: DashboardWidget;
	selectedDate?: TNewDateRange;
	filters: DashboardFilter[];
	isEditModalOpen: boolean;
	isEdit: boolean;
	toggleEdit: () => void;
	updateWidget?: (widget: DashboardWidget) => void;
};

export type TextObjectType = {
	type: 'p' | 'h2' | 'h3';
	value: string;
};

const TextView = (props: Props) => {
	const { widget, selectedDate, filters, isEdit, toggleEdit, updateWidget, isEditModalOpen } =
		props;
	const { addToast } = useToast();
	const { currentDatePeriods, measures, unitTypes } = useApplication();
	const defaultDatePeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [isLoading, setIsLoading] = useState<boolean>(true);
	const [widgetData, setWidgetData] = useState<string>();
	const [widgetContent, setWidgetContent] = useState<string>('');
	const previousSelectedStartPeriod = localStorage.getItem('currentSelectedStartPeriod');
	const previousSelectedEndPeriod = localStorage.getItem('currentSelectedEndPeriod');
	const hasPreviouslySelectedPeriod =
		previousSelectedStartPeriod != null || previousSelectedEndPeriod != null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedStartDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedStartPeriod
			? JSON.parse(previousSelectedStartPeriod)
			: null;
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	const previouslySelectedEndDate: TDateRange | null =
		hasPreviouslySelectedPeriod && previousSelectedEndPeriod
			? JSON.parse(previousSelectedEndPeriod)
			: null;
	const [editState, setEditState] = useState<EditorState>(EditorState.createEmpty());
	const [isFactAliasDropdownOpen, setIsFactAliasDropdownOpen] = React.useState(false);
	const [conditionalFormattingModalOpen, setConditionalFormattingModalOpen] = useState(false);
	const [addFactAliasModalOpen, setAddFactAliasModalOpen] = useState(false);
	const [currentFact, setCurrentFact] = useState<DraggableMenuItemData | null>(null);
	const prevSelectedDate = usePrevious();
	const prevFilters = usePrevious();
	const [factAliasDropdownItems, setFactAliasDropdownItems] = useState<JSX.Element[]>([]);
	const [dataframes, setDataframes] = useState<TDataframe[]>([]);

	useMount(() => {
		setInitialState();
	});

	useEffect(() => {
		if (!_.isEqual(selectedDate, prevSelectedDate) || !_.isEqual(filters, prevFilters)) {
			const handler = setTimeout(() => {
				if (getFactAliases().length && !isEdit) {
					getWidgetData();
				} else {
					setIsLoading(false);
				}
			}, 500);

			return () => {
				clearTimeout(handler);
			};
		}
	}, [selectedDate, filters]);

	useEffect(() => {
		setWidgetContent(widgetData ?? widget.content ?? '');
	}, [widget, widgetData]);

	useEffect(() => {
		if (getFactAliases().length) {
			if (!dataframes.length && isEdit) {
				getDataframesForAliases();
			} else {
				setupFactAliasDropdownItems();
			}
		}
	}, [dataframes]);

	const getFactAliases = (
		onLoadContent?: string
	): Array<{
		dataframeId: number;
		factAlias: string;
	}> => {
		const regex = /@dataframe-([\w-]+)\.([\w-]+)/g;
		const content = onLoadContent ?? draftToHtml(convertToRaw(editState.getCurrentContent()));
		const matches = content.match(regex);

		if (matches) {
			return matches.map((match) => {
				const parts = match.substring(11).split('.');
				return {
					dataframeId: +parts[0],
					factAlias: parts[1],
				};
			});
		}

		return [];
	};

	const setInitialState = () => {
		let html = '';
		if (widget.widget_type === 'text' && widget.content) {
			if (isJsonString(widget.content)) {
				const jsonObject = JSON.parse(widget.content) as TextObjectType;
				html = jsonObject.value;
			} else {
				html = widget.content;
			}
		}
		setEditState(getEditorStateFromString(html));
		setWidgetContent(widget.content ?? '');

		if (getFactAliases(widget.content).length) {
			if (isEdit) {
				getDataframesForAliases(widget.content);
			} else {
				getWidgetData();
			}
		} else {
			setIsLoading(false);
		}
	};

	const getEditorStateFromString = (value: string) => {
		let currentState = EditorState.createEmpty();
		const contentBlock = htmlToDraft(value);
		if (contentBlock) {
			const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
			currentState = EditorState.createWithContent(contentState);
		}
		return currentState;
	};

	const getDataframesForAliases = (onLoadContent?: string) => {
		setIsLoading(true);
		const factAliases = getFactAliases(onLoadContent);

		Dataframe.GetByIds(
			factAliases.map((alias) => alias.dataframeId),
			['datasets']
		)
			.then((responses) => {
				setDataframes(responses);
				setupFactAliasDropdownItems();
				setIsLoading(false);
			})
			.catch((): void => {
				addToast('Error fetching dataframe data for fact aliases', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const getWidgetData = () => {
		setIsLoading(true);
		const previousDate = {
			begin_date:
				hasPreviouslySelectedPeriod && previouslySelectedStartDate
					? previouslySelectedStartDate?.begin_date
					: defaultDatePeriod?.begin_date ?? 0,
			end_date:
				hasPreviouslySelectedPeriod && previouslySelectedEndDate
					? previouslySelectedEndDate.end_date
					: defaultDatePeriod?.end_date ?? 0,
			period:
				hasPreviouslySelectedPeriod && previouslySelectedStartDate
					? previouslySelectedStartDate.period
					: defaultDatePeriod?.period ?? 0,
		};

		const dateToUse = selectedDate ?? previousDate;

		const request = {
			widgetId: widget.id ?? 0,
			periodId: dateToUse?.period ?? 0,
			startDate: dateToUse?.begin_date ?? 0,
			endDate: dateToUse?.end_date ?? 0,
			filters:
				filters?.map((filter) => {
					return {
						entity_id: filter.entity_id,
						entity_type: filter.entity_type,
						excluded: filter.excluded,
						isExistingValue: filter.isExistingValue,
						operator: filter.operator,
						value: filter.value,
					};
				}) ?? [],
		};

		DashboardWidgetService.GetTextWidgetData(request)
			.then((response) => {
				setWidgetData(response.content);
				setIsLoading(false);
			})
			.catch((): void => {
				addToast('Error fetching text widget data', AlertVariant.danger);
				setIsLoading(false);
			});
	};

	const isJsonString = (jsonString: string): boolean => {
		try {
			const o = JSON.parse(jsonString) as IIndexable;

			if (o && typeof o === 'object') {
				return true;
			}
		} catch (e) {
			return false;
		}

		return false;
	};

	const onFactAliasDropdownToggle = (isOpen: boolean) => {
		setIsFactAliasDropdownOpen(isOpen);
	};

	const onFactAliasSelect = () => {
		setIsFactAliasDropdownOpen(false);
		onFactAliasDropdownFocus();
	};

	const onFactAliasDropdownFocus = () => {
		const element = document.getElementById('fact-alias-dropdown');
		element?.focus();
	};

	const removeFactAlias = (alias: string, dataframeId: number) => {
		const contentState = editState.getCurrentContent();
		const contentText = contentState.getPlainText();

		const index = contentText.indexOf(alias);
		if (index === -1) return; // If the string doesn't exist, do nothing.

		// Create a selection around the target string.
		const selectionState = SelectionState.createEmpty(
			contentState.getFirstBlock().getKey()
		).merge({
			anchorOffset: index,
			focusOffset: index + alias.length,
		});

		// Remove the target string.
		const newContentState = Modifier.removeRange(contentState, selectionState, 'forward');

		// Push the updated content back into the editor state.
		const newEditorState = EditorState.push(editState, newContentState, 'remove-range');

		setEditState(newEditorState);
		setDataframes((prev) => {
			return prev.filter((frame) => frame.id != dataframeId);
		});
	};

	const setupFactAliasDropdownItems = () => {
		const factAliases = getFactAliases();

		setFactAliasDropdownItems(
			factAliases.map((alias) => {
				const frame = dataframes.find((d) => d.id === alias.dataframeId);
				const set = new Set(frame?.datasets.map((s) => s.keyMeasureFact));
				const measure = measures.find((m) =>
					m.keyMeasureFacts.some((kmf) => set.has(kmf.id))
				);
				const fact = measure?.keyMeasureFacts.find((kmf) => kmf.alias === alias.factAlias);
				const aliasText = buildAliasString(alias.dataframeId, alias.factAlias);

				return (
					<DropdownItem key={`fact-alias-${fact?.id ?? 0}`}>
						<div className="fact-alias-item">
							<div className="fact-alias-text">{fact?.name}</div>
							<div className="fact-alias-actions">
								<Tooltip
									content={<div>{measure?.display_name ?? measure?.name}</div>}
								>
									<InfoCircleIcon />
								</Tooltip>
								<Tooltip content={<div>Configure Conditional Formatting</div>}>
									<CogIcon
										onClick={(e) => {
											setCurrentFact({
												id: generateGUID(),
												entity: fact,
												data: populateDataForFact(
													fact!,
													unitTypes.find(
														(type) => type.id === fact?.unitType
													)
												),
												entityType: OptionsBuilderItemTypes.KeyMeasureFact,
												itemType: 'dropdown',
												allowedZones: [],
												static: false,
												icon: 'database',
											});
											setConditionalFormattingModalOpen(true);
										}}
									/>
								</Tooltip>
								<Tooltip content={<div>{`Copy ${aliasText} to Clipboard`}</div>}>
									<CopyIcon
										onClick={() => {
											// eslint-disable-next-line @typescript-eslint/no-floating-promises
											navigator.clipboard.writeText(aliasText);
										}}
									/>
								</Tooltip>
								<Tooltip content={<div>Remove Fact Alias</div>}>
									<TimesIcon
										onClick={() => {
											removeFactAlias(aliasText, alias.dataframeId);
										}}
									/>
								</Tooltip>
							</div>
						</div>
					</DropdownItem>
				);
			})
		);
	};

	const getCustomToolbarItems = () => {
		return [
			<Button
				variant="link"
				key="add-data"
				onClick={() => {
					setAddFactAliasModalOpen(true);
				}}
				isInline
			>
				Add Data
			</Button>,
			...(getFactAliases().length
				? [
						<Dropdown
							onSelect={onFactAliasSelect}
							toggle={
								<DropdownToggle
									id="fact-alias-dropdown"
									onToggle={onFactAliasDropdownToggle}
								>
									Select a Fact
								</DropdownToggle>
							}
							isOpen={isFactAliasDropdownOpen}
							dropdownItems={factAliasDropdownItems}
						/>,
				  ]
				: []),
		];
	};

	const onConditionalFormattingModalClose = (): void => {
		setConditionalFormattingModalOpen(false);
	};

	const onConditionalFormattingModalSave = (rules: ConditionalRule[]): void => {
		const factIds = rules.map((rule) => rule.key_measure_fact_id);

		props.updateWidget &&
			props.updateWidget({
				...widget,
				conditionalRules: [
					...(widget.conditionalRules
						? widget.conditionalRules.filter(
								(rule: ConditionalRule) =>
									!factIds.includes(rule.key_measure_fact_id)
						  )
						: []),
					...rules.map((widgetRule) => {
						return {
							...widgetRule,
							...{ widget: widget.id ?? 0 },
						};
					}),
				],
			});

		setConditionalFormattingModalOpen(false);
	};

	const addFactAlias = (fact: TKeyMeasureFact, dataframe?: TDataframe) => {
		let aliasString = '';
		const measure = measures.find((m) =>
			m.keyMeasureFacts.some((factToUse) => factToUse.id === fact.id)
		);

		if (measure) {
			const datasets = populateDroppedFactsByKeyMeasure(
				measure.id,
				fact.id,
				measures,
				unitTypes
			);

			datasets.forEach((set) => {
				if (set.data) {
					set.data.unitType =
						typeof set.data.unitType === 'number' ? set.data.unitType : 0;
				}
			});

			const dataframeRequest = buildDataframeRequest(
				datasets,
				[],
				[],
				[],
				'',
				[],
				false,
				false,
				undefined,
				undefined,
				true
			);

			dataframeRequest.parent = dataframe ? dataframe.id : measure.dataframe;

			Dataframe.NewDataframe(dataframeRequest as TNewDataframe)
				.then((newResponse: TDataframe) => {
					void Dataframe.Get(newResponse.id, ['datasets']).then((response) => {
						setDataframes([...dataframes, response]);

						if (dataframe) {
							aliasString = buildAliasString(newResponse.id, fact.alias!);
							setDataframes([...dataframes, dataframe]);
						} else {
							aliasString = buildAliasString(newResponse.id, fact.alias!);
						}

						addAliasString(aliasString);
					});
				})
				.catch((): void => {
					addToast('Error creating child dataframe.', AlertVariant.danger);
					setIsLoading(false);
				});
		}
	};

	const buildAliasString = (dataframeId: number, alias: string) => {
		return `@dataframe-${dataframeId}.${alias}`;
	};

	const addAliasString = (alias: string) => {
		const currentContent = editState.getCurrentContent();
		const currentSelection = editState.getSelection();
		const newContent = Modifier.replaceText(currentContent, currentSelection, alias);
		const newEditorState = EditorState.push(editState, newContent, 'insert-characters');
		setEditState(EditorState.forceSelection(newEditorState, newContent.getSelectionAfter()));
	};

	const optionsModalProps: ZiCreateSelectionOptionsModalProps = {
		isOpen: addFactAliasModalOpen,
		onClose: () => {
			setAddFactAliasModalOpen(false);
		},
		onSuccess: (fact, dataframe) => {
			addFactAlias(fact, dataframe);
			setAddFactAliasModalOpen(false);
		},
		defaultView: ViewTypesEnum.Default,
		title: 'Add Data',
		urlBase: '',
		viewOptions: [
			{
				type: ViewTypesEnum.Measure,
				icon: faChartLineUp,
				label: 'Key Measures',
			},
			{
				type: ViewTypesEnum.Dataframe,
				icon: faDatabase,
				label: 'Dataframe',
			},
		],
		navigateOnModalClose: false,
	};

	function usePrevious(value?: any) {
		const ref = useRef();
		useEffect(() => {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			ref.current = value;
		});
		return ref.current;
	}

	const componentStyles = {
		height: '100%',
		width: '100%',
		overflow: 'auto',
	};

	let component = <Loader />;

	if (!isLoading) {
		component = (
			<div style={componentStyles}>
				<Text
					id={`text-widget-${widget.id ?? '0'}`}
					className="text-widget"
					dangerouslySetInnerHTML={{
						__html: editState
							? draftToHtml(
									convertToRaw(
										getEditorStateFromString(widgetContent).getCurrentContent()
									)
							  )
							: '',
					}}
				></Text>
				<Modal
					className="edit-text-widget-modal"
					aria-label="Edit Widget Modal"
					isOpen={isEditModalOpen}
					variant={ModalVariant.large}
					showClose={false}
					actions={[
						<Button
							key="confirm"
							variant="primary"
							onClick={() => {
								widget.id &&
									updateWidget &&
									updateWidget({
										...widget,
										widgetChanged: true,
										content: editState
											? draftToHtml(
													convertToRaw(editState.getCurrentContent())
											  )
											: '',
									});
								toggleEdit();
								setIsFactAliasDropdownOpen(false);
							}}
						>
							Save
						</Button>,
						<Button
							key="cancel"
							variant="link"
							onClick={() => {
								setInitialState();
								toggleEdit();
								setIsFactAliasDropdownOpen(false);
							}}
						>
							Cancel
						</Button>,
					]}
				>
					<InputGroup>
						<Editor
							editorState={editState}
							toolbar={{
								options: ['inline', 'fontFamily', 'fontSize', 'textAlign'],
								inline: {
									options: ['bold', 'italic', 'underline', 'strikethrough'],
								},
								fontFamily: {
									options: [
										'Arial',
										'Georgia',
										'Impact',
										'Tahoma',
										'Times New Roman',
										'Verdana',
									],
								},
								fontSize: {
									options: [
										8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96,
									],
								},
							}}
							onEditorStateChange={(e) => {
								setEditState(e);
							}}
							toolbarCustomButtons={getCustomToolbarItems()}
						/>
					</InputGroup>
				</Modal>
				<>
					{conditionalFormattingModalOpen && (
						<ConditionalFormattingModal
							fact={currentFact}
							rules={widget.conditionalRules ?? []}
							handleSave={onConditionalFormattingModalSave}
							handleClose={onConditionalFormattingModalClose}
						/>
					)}
					<ZiCreateSelectionModal {...optionsModalProps} />
				</>
			</div>
		);
	}
	return component;
};

export default TextView;
