import { Button, ExpandableSection, Text, Title } from '@patternfly/react-core';
import React, { useState } from 'react';
import { CategoryQuestion } from '../../types/discover';
import ChartView from '../../components/charts/ChartView';
import { LambdaInsightsResponse, TDateRange } from '../../api/types';
import LambdaInsights from '../../components/data-builder/LambdaInsights';
import Loader from '../../components/util/Loader';
import { useApplication } from '../../components/user/ApplicationProvider';
import { selectedPeriod } from '../../helpers/selected-period.helper';
import { TNewDateRange } from '../../api/types/TNewDateRange';
import { DateRange } from '../../api/date-period-selector/DateRange';

type Props = {
	question: CategoryQuestion;
};

const Question = (props: Props) => {
	const [isExpanded, setIsExpanded] = useState<boolean>(false);
	const [lambdaInsights, setLambdaInsights] = useState<LambdaInsightsResponse | undefined>();
	const [fetchLambdaInsights, setFetchLambdaInsights] = useState<boolean>(false);
	const [showLambdaInsights, setShowLambdaInsights] = useState<boolean>(false);
	const [lambdaInsightsLoading, setLambdaInsightsLoading] = useState<boolean>(false);
	const { question } = props;
	const { currentDatePeriods } = useApplication();
	const currentPeriod = selectedPeriod();
	const defaultPeriod =
		currentDatePeriods.find((dp) => dp.period === 3) ?? (DateRange.Default() as TDateRange);
	const [selectedDate, _] = useState<TNewDateRange | undefined>({
		begin_date: currentPeriod.startPeriod
			? currentPeriod.startPeriod.begin_date
			: defaultPeriod.begin_date ?? 0,
		end_date: currentPeriod.endPeriod
			? currentPeriod.endPeriod.end_date
			: defaultPeriod.end_date ?? 0,
		period: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.period ?? 0,
		sequence: currentPeriod.startPeriod
			? currentPeriod.startPeriod.period
			: defaultPeriod.sequence ?? 0,
	});

	const onToggle = () => {
		setIsExpanded(!isExpanded);
	};

	return (
		<ExpandableSection
			toggleText={question.question}
			onToggle={onToggle}
			isExpanded={isExpanded}
			isIndented
			data-testid={`question-${question.key}`}
		>
			{question.title && <Title headingLevel="h2">{question.title}</Title>}
			{question.description && <Text>{question.description}</Text>}
			{question.hasChart && (
				<div className={'chart-data'}>
					<div className={'chart-section'}>
						<ChartView
							chartId={question.chartId ?? '0'}
							selectedDate={selectedDate}
							allowClickNavigate
							transparentBackground={true}
							fetchInsights={fetchLambdaInsights}
							setLambdaInsights={(e) => {
								setLambdaInsights(e);
								setLambdaInsightsLoading(!lambdaInsightsLoading);
							}}
							setHasRetrievalData={setShowLambdaInsights}
						/>
					</div>

					{showLambdaInsights && (
						<div className={'insights-section'}>
							{!lambdaInsights && !lambdaInsightsLoading && (
								<Button
									key="generate-insights"
									variant="primary"
									className={'generate-insights-btn'}
									onClick={() => {
										setFetchLambdaInsights(true);
										setLambdaInsightsLoading(true);
									}}
								>
									AI * Insights
								</Button>
							)}

							{lambdaInsightsLoading && <Loader />}

							{lambdaInsights && !lambdaInsightsLoading && (
								<LambdaInsights
									insights={lambdaInsights}
									hasBackground={true}
								/>
							)}
						</div>
					)}
				</div>
			)}
		</ExpandableSection>
	);
};

export default Question;
